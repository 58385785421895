import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import Content from '../components/Content';

const ThanksPage = () => {
  return (
    <Layout>
      <HelmetDatoCms title="Thanks | BGA" />
      <main>
        <Banner title="Thanks" />
        <Content content="<p>Thanks for getting in touch, we'll get back to you soon.</p>" />
      </main>
    </Layout>
  );
};

export default ThanksPage;
